import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PrivilegeItem, VivaWalletFee } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';

interface UIVivaWalletFee extends VivaWalletFee {
  deleted: boolean;
}

@autoinject
export class PosVivaWalletFeeList {
  private fieldList: FieldSpec[] = [
    { key: "feeCents", header: "vivaWallet.feeCents", type: "number", minimumFractionDigits: 0 },
    { key: "feePercentage", header: "vivaWallet.feePercentage", type: "number", minimumFractionDigits: 1 },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
  private itemList: UIVivaWalletFee[] = [];
  private privilege?: PrivilegeItem;
  private isMaster = false;

  constructor(private readonly api: MyHttpApi, private readonly router: Router) {
  }

  async activate(params: { isMaster: string; }) {
    let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
    this.isMaster = params.isMaster === "true";
    if (!this.isMaster) {
      this.privilege = getSelectedCorporation();
      this.fieldList = [...this.fieldList,
      { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
      { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
      { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
      ];
    }
    await this.search();
  }

  async search() {
    if (!this.isMaster && !this.privilege) {
      return;
    }
    const itemList = await this.api.vivaWalletFeeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.map(x => ({...x, deleted: !!x.deleteTime}));
  }

  rowCall(key: string, row?: VivaWalletFee) {
    this.router.navigateToRoute(this.editUrl, { id: row?.id, isMaster: this.isMaster });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }

  @computedFrom("privilege.id", "privilege.type", "itemList", "isMaster")
  get showAdd() {
    if (this.privilege?.type === 'CLIENT') {
      return !this.itemList.find(x => x.clientId === this.privilege?.id);
    } else if (this.privilege?.type === "BUSINESS_GROUP") {
      return !this.itemList.find(x => x.businessGroupId === this.privilege?.id);
    } else if (this.privilege?.type === 'CORPORATION') {
      return !this.itemList.find(x => x.corporationId === this.privilege?.id);
    }
    return false;
  }
}
