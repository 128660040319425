import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Location, MyHttpApi, VivaWalletPaymentInfoUpdateRequest } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';

@autoinject
export class PosVivaWalletPaymentInfoEdit {
  private paymentInfo: VivaWalletPaymentInfoUpdateRequest = {
    merchantId: "",
    posApiClientId: "",
    posApiClientSecret: "",
    sourceCode: "",
    pinCode: "",
        type: "CLIENT",
        delete: false,
  }
  private clientId?: number;
  private typeAndName = "";
  private extraLocation?: Location = undefined;
  private extraLocationId?: string = undefined;
  private locationList: Location[] = [];

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate(params: { id?: string; clientId?: number }) {
    if (params.id) {
      let vivaWalletPaymentInfo = await this.api.vivaWalletPaymentInfoById({ id: params.id, });
      this.paymentInfo = {
        ...vivaWalletPaymentInfo,
        delete: !!vivaWalletPaymentInfo.deleteTime,
        type: 'CLIENT'
      };
      this.clientId = privilegeItemFromElement(vivaWalletPaymentInfo)?.id;
      this.extraLocationId = this.paymentInfo.locationId;
    }
    if (!params.id) {
      this.clientId = getSelectedClient() || params.clientId;
    }

    const [typeAndName, locationList] = await Promise.all([
      clientIdToTypeAndName(this.api, this.i18n, this.clientId),
      this.api.locationList({ id: this.clientId }),
    ]);
    this.typeAndName = typeAndName;
    this.extraLocation = locationList.find(x => x.id == this.extraLocationId);
    this.locationList = locationList.filter(x => !x.deleteTime);
  }

  async save(deleted: boolean) {
    if(!this.paymentInfo) {
      return;
    }
    await this.api.vivaWalletPaymentInfoUpdate({
      ...this.paymentInfo,
      privilegeId: this.clientId,
      type: 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
