import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, VatCategoryUpdateRequest } from 'utils/api';
import { VatCategoryUpdateRow } from '../utils/api';

class UIVatCategoryUpdateRow {
  public originalStartDate: Date;

  constructor(public row: VatCategoryUpdateRow, public saved: boolean) {
    this.originalStartDate = row.startDate;
  }

  @computedFrom("saved", "originalStartDate")
  get disabled() {
    return this.saved && this.originalStartDate <= new Date();
  }

  @computedFrom("disabled")
  get minDate() {
    return this.disabled ? this.row.startDate : new Date();
  }
}

@autoinject
export class PosVatCategoryEdit {
  private vatCategory: VatCategoryUpdateRequest = {
    name: "",
    rows: [],
  };
  private rows: UIVatCategoryUpdateRow[] = [];
  private defaultDate = new Date();

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id?: string; }) {
    if (params.id) {
      let vc = await this.api.vatCategoryById({ id: parseInt(params.id) });
      this.vatCategory = { ...vc.vatCategory, rows: [] };
      this.rows = vc.rows.map(vcr => new UIVatCategoryUpdateRow(vcr, true));
      if (this.rows.length) {
        let datesAsNumberArray = vc.rows.map(x => x.startDate.getTime());
        this.defaultDate = new Date(Math.max(...datesAsNumberArray));
      }
    }
  }

  deleteRow(row: UIVatCategoryUpdateRow) {
    this.rows = this.rows.filter(x => x !== row);
  }

  addRow() {
    const item = new UIVatCategoryUpdateRow({
      startDate: this.defaultDate,
      vatPercent: 0,
    }, false);
    this.rows = [...this.rows, item];
  }

  async save() {
    if (!this.rows.length) {
      return;
    }
    await this.api.vatCategoryUpdate({
      ...this.vatCategory,
      rows: this.rows.map(x => x.row),
    });
    this.router.navigateBack();
  }
}
